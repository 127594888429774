import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import {IntlProvider} from "react-intl";
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import German from './lang/de.json';
import English from './lang/en.json';
import French from './lang/fr.json';
import Dutch from './lang/nl.json';

import GummiesTemplate from "./templates/GummiesTemplate";
import ChakraTheme from "./templates/Chakra";
import Home from "./pages/home/Home";
import Step2 from "./pages/step2/Step2";
import Step1 from "./pages/step1/Step1";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

function App() {
    const currentUrl = window.location.toString();

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    let trust7Locale = 'de-DE';
    let trust7Channel = 'nslim:de';

    switch (true) {
        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            moment.locale('nl');

            trust7Locale = 'nl-NL';
            trust7Channel = 'nslim:nl';
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            moment.locale('fr');

            trust7Locale = 'fr-FR';
            trust7Channel = 'nslim:fr';
            break;

        case (currentUrl.search('/uk') !== -1):
            messages = English;
            locale = 'en';
            moment.locale('en');

            trust7Locale = 'en-GB';
            trust7Channel = 'nslim:en';
            break;

        // case (currentUrl.search('/no') !== -1):
        //     messages = Norwegian;
        //     locale = 'no';
        //     moment.locale('nb');
        //
        //     trust7Locale = 'nb-NO';
        //     trust7Channel = 'nslim:no';
        //     break;
        //
        // case (currentUrl.search('/dk') !== -1):
        //     messages = Danish;
        //     locale = 'dk';
        //     moment.locale('da');
        //
        //     trust7Locale = 'da-DK';
        //     trust7Channel = 'nslim:dk';
        //     break;

        case (currentUrl.search('/ie') !== -1):
            messages = English;
            messages.outgoingUrl = 'https://buynslim.com/ie/buy-now.html';
            locale = 'en-ie';
            moment.locale('en');

            trust7Locale = 'en-IE';
            trust7Channel = 'nslim:en';
            break;

        case (currentUrl.search('/at') !== -1):
            messages.outgoingUrl = 'https://buynslim.com/at/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Wien';
            messages['step10.headline'] = 'Svenja aus Graz';
            break;

        case (currentUrl.search('/ch') !== -1):
            messages.outgoingUrl = 'https://buynslim.com/ch/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Basel';
            messages['step10.headline'] = 'Svenja aus Zürich';
            locale = 'de-ch';
            break;

        default:
    }

    // load trust7 widget sdk and ensure it is only loaded once
    if (!document.getElementById('trust7-sdk')) {
        const trust7 = document.createElement('script');
        trust7.setAttribute('id', 'trust7-sdk');
        trust7.setAttribute('type', 'module');
        trust7.setAttribute('src', `https://elements.trust7.app/${trust7Locale}/widget-elements.js`);
        document.head.appendChild(trust7);
    }

    document.title = messages.title;

    moment.locale(locale);

    const config = {
        locale: locale,
        trust7Channel: trust7Channel,
        trust7Locale: trust7Locale,
    }

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <GummiesTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2 config={config}/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete config={config}/>
                            }/>
                        </Routes>
                    </GummiesTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
